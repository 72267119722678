import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { useRouter } from "next/router";
import { useCreateNewPage } from "@hooks/tablet-hooks";
import { differenceInDays, parseISO } from "date-fns";
import { motion } from "framer-motion";

import Router from "next/router";
import Head from "next/head";

import NavBar from "@landing-components/NavBar/NavBar";
import Input, { emailValidator } from "@components/Input/Input";
import Button from "@components/Button/Button";
import Footer from "@landing-components/Footer/Footer";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Loader from "@components/Loader/Loader";

import { withApollo } from "@modules/apollo";

import "./login.scss";

const LOGIN = gql`
    mutation login($input: LoginInput!) {
        login(input: $input) {
            id
            email
            firstName
            lastName
            role
            verified
            workspaces {
                id
            }
            activeWorkspace {
                id
            }

            tabletViews(order: DESC) {
                viewedAt
                tablet {
                    id
                    workspace {
                        id
                    }
                }
            }
        }
    }
`;

const GENERATE_RESET_EMAIL = gql`
    mutation generateResetEmail($input: GenerateResetEmailInput!) {
        generateResetEmail(input: $input)
    }
`;

const LoginPage = (props) => {
    const router = useRouter();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mode] = router?.query?.slug || [];
    const resetPasswordMode = mode === "reset";

    const [errorMessage, setErrorMessage] = useState();
    const [login, { loading: requestingLogin, called, data, error }] = useMutation(LOGIN, { onError() {} });
    const [generateResetEmail, { loading: generatingEmail, data: generateEmailRes }] = useMutation(
        GENERATE_RESET_EMAIL
    );
    const [createNewPage, { loading: pageCreationInProgress }] = useCreateNewPage();
    const [loggingIn, setLoggingIn] = useState();
    const [googleOauthLoggingIn, setGoogleOauthLoggingIn] = useState(false);

    const redirectTo = decodeURIComponent(router.query.redirect_to || "");
    const googleOauthHref = redirectTo
        ? `${process.env.NEXT_PUBLIC_API_URL}/auth/login/google?login_hint=${email}&redirect_to=${redirectTo}`
        : `${process.env.NEXT_PUBLIC_API_URL}/auth/login/google?login_hint=${email}`;

    const hasEnteredDetails = email && password;

    useEffect(() => {
        if (!router.query) return;
        if (router.query.reason === "hyperactive-auth") {
            setErrorMessage(
                "Sorry! Scribe is experiencing a glitch that occasionally results in you getting logged out. Log back in to go to your previous page. This fix is my #1 Priority."
            );
        }
    }, [router.query]);

    function handleLogin(e) {
        e.preventDefault();
        if (email && password) {
            login({ variables: { input: { email, password } } });
        }
    }

    // Necessary because we redirect on the client side so there is some delay
    useEffect(() => {
        if (requestingLogin) {
            setLoggingIn(true);
        } else if (loggingIn && !pageCreationInProgress) {
            setLoggingIn(false);
        }
    }, [requestingLogin, pageCreationInProgress]);

    useEffect(() => {
        if (!data) return;

        async function handleLoginRedirect() {
            const { firstName, lastName, verified, activeWorkspace, workspaces = [], tabletViews } =
                data?.login || {};

            if (!firstName || !lastName) Router.push("/onboarding/details");
            else if (workspaces.length === 0) Router.push("/onboarding/workspace");
            else if (!verified) Router.push("/onboarding/verify");
            // else if (redirectTo) {
            //     const localRoute = redirectTo.split(process.env.NEXT_PUBLIC_FRONTEND_URL)[1];
            //     console.log(localRoute);
            //     if (localRoute === redirectTo || localRoute.match(/[\.]|http(s)?|(\/\/)/g)) {
            //         // Someone has tried to pull a dodgy, this redirectTo url doesn't belong to our domain or it contains characters it shouldn't
            //         throw new Error(
            //             "The redirect URL is incorrect. Please report this to support@scribe.wiki"
            //         );
            //     } else {
            //         // This only routes locally (not to external sites) so it should protect against dodgy redirectURLs
            //         Router.push(localRoute);
            //     }
            // }
            else {
                const newPageWorkspaceId = activeWorkspace?.id || workspaces[0].id;
                const mostRecentPageView = tabletViews.find(({ tablet }) => !!tablet);

                if (mostRecentPageView) {
                    const daysSinceCreated = differenceInDays(
                        new Date(),
                        parseISO(mostRecentPageView.viewedAt)
                    );

                    // If you were on a page less than 2 or less days ago then it's not a bad assumption to assume they
                    // want to go back to that page.
                    if (daysSinceCreated < 3) {
                        Router.push(
                            "/[workspace]/page/[page]/edit",
                            `/${mostRecentPageView.tablet.workspace.id}/page/${mostRecentPageView.tablet.id}/edit`
                        );

                        return;
                    }
                }

                await createNewPage({}, true, { workspaceId: newPageWorkspaceId });
            }
        }

        handleLoginRedirect();
    }, [data]);

    useEffect(() => {
        if (error) {
            setErrorMessage(
                "Unfortunately we weren't able to log you in. Please double check your email and password are correct."
            );
        }
    }, [error]);

    async function handleSendResetLink(event) {
        event.preventDefault();
        generateResetEmail({ variables: { input: { email } } });
    }

    function handleResetClick() {
        Router.push("/login/[[...slug]]", `/login/reset`, { scroll: true, shallow: true });
    }

    function handleGoToLoginClick() {
        Router.push("/login/[[...slug]]", `/login`, { scroll: true, shallow: true });
    }

    return (
        <div className="login-page text-block" data-error={!!errorMessage}>
            <Head>
                <title key="title">Scribe: Sign in</title>
            </Head>
            <NavBar theme="light" mode="access" border={true} />
            <div className="container">
                <h1 className="heading">Login</h1>

                {!!errorMessage && (
                    <div className="error-message-container">
                        <ErrorMessage message={errorMessage} onClose={() => setErrorMessage(null)} />
                    </div>
                )}

                <a
                    href={googleOauthHref}
                    className="google-btn"
                    onClick={() => setGoogleOauthLoggingIn(true)}
                >
                    {!googleOauthLoggingIn && (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                className="google-icon"
                            >
                                <g fill="none" fillRule="evenodd">
                                    <path
                                        fill="#4285F4"
                                        d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
                                    />
                                    <path
                                        fill="#34A853"
                                        d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
                                    />
                                    <path
                                        fill="#FBBC05"
                                        d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
                                    />
                                    <path
                                        fill="#EA4335"
                                        d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
                                    />
                                    <polygon points="0 0 18 0 18 18 0 18" />
                                </g>
                            </svg>

                            <span>Log in with Google</span>
                        </>
                    )}
                    {googleOauthLoggingIn && <Loader theme="light" />}
                </a>

                <div className="seperator"></div>

                {!resetPasswordMode && (
                    <motion.form
                        className="email-login-container"
                        onSubmit={handleLogin}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        <Input
                            label="Email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            type="email"
                            contained={true}
                            validators={[emailValidator]}
                            showErrorMessage={({ hasFocus, hasBlurred, hasTyped }) =>
                                hasTyped && (!hasFocus || hasBlurred)
                            }
                        />

                        <Input
                            label="Password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            type="password"
                            contained={true}
                        />

                        <div className="form-btns-container">
                            <div className="main-btn-container">
                                <Button
                                    variant={hasEnteredDetails ? "primary" : "secondary"}
                                    size="medium"
                                    disabled={!email || !password}
                                    loading={loggingIn}
                                >
                                    Log in
                                </Button>
                            </div>
                            <div className="secondary-btn-container">
                                <button className="secondary-btn" onClick={handleResetClick}>
                                    Forgot Password?
                                </button>
                            </div>
                        </div>
                    </motion.form>
                )}
                {resetPasswordMode && (
                    <>
                        {!generateEmailRes?.generateResetEmail && (
                            <motion.form
                                className="email-reset-password-container"
                                onSubmit={handleSendResetLink}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                            >
                                <Input
                                    label="Email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    type="email"
                                    contained={true}
                                    validators={[emailValidator]}
                                    showErrorMessage={({ hasFocus, hasBlurred, hasTyped }) =>
                                        hasTyped && (!hasFocus || hasBlurred)
                                    }
                                />

                                <div className="form-btns-container">
                                    <div className="main-btn-container">
                                        <Button
                                            variant={!!email ? "primary" : "secondary"}
                                            size="medium"
                                            disabled={!email}
                                            loading={generatingEmail}
                                        >
                                            Send Reset Link
                                        </Button>
                                    </div>
                                    <div className="secondary-btn-container">
                                        <button className="secondary-btn" onClick={handleGoToLoginClick}>
                                            Return to sign in
                                        </button>
                                    </div>
                                </div>
                            </motion.form>
                        )}

                        {generateEmailRes?.generateResetEmail && (
                            <p className="sent-reset-email-message">
                                Check your inbox for the link to reset your password
                            </p>
                        )}
                    </>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default withApollo(LoginPage);
