import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import VisuallyHidden from "@reach/visually-hidden";

import "./ErrorMessage.scss";

const propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool,
};

const ErrorMessage = ({ message, onClose, fullScreen = false }) => {
    return (
        <div data-error-message data-full-screen={fullScreen}>
            <p>{message}</p>
            <button className="close-button" onClick={onClose}>
                <VisuallyHidden>Close</VisuallyHidden>
                <FontAwesomeIcon icon={faTimes} aria-hidden />
            </button>
        </div>
    );
};

ErrorMessage.propTypes = propTypes;

export default ErrorMessage;
